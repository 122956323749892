// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import TagManager from 'react-gtm-module'
// import loadScript from "./loadExternalScript";

// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//     <App />
// );

// reportWebVitals();

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

// GTM Configuration
const tagManagerArgs = {
    gtmId: 'GTM-TBW735LP',
};

// Helper function to load external scripts
const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

// Render the React app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Load external scripts after the app has rendered
const loadExternalScripts = async () => {
  try {
    await loadScript(`${process.env.PUBLIC_URL}/assets/js/bootstrap.bundle.min.js`);
    await loadScript(`${process.env.PUBLIC_URL}/assets/js/jquery.min.js`);
    console.log("Scripts loaded successfully!");
  } catch (error) {
    console.error("Error loading scripts:", error);
  }
};

// Call the function to load scripts
loadExternalScripts();
// Initialize GTM after rendering the app
TagManager.initialize(tagManagerArgs);

reportWebVitals();
