import React,{lazy,Suspense} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoginPopup } from "./Component/utitls/LoginPopup";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
const Home = lazy(()=> import('./Page/Home') );

const AboutUs = lazy(()=> import('./Page/AboutUs') );
const Product = lazy(()=> import('./Page/Product') );
const Research = lazy(()=> import('./Page/Research') );
const Infrastructure = lazy(()=> import('./Page/Infrastructure') );
const Network = lazy(()=> import('./Page/Network') );
const Store = lazy(()=> import('./Page/Store') );
const Careers = lazy(()=> import('./Page/Careers') );
const ReachUs = lazy(()=> import('./Page/ReachUs') );
const ProductCategoryHalf = lazy(()=> import('./Page/ProductCategoryHalf') );
const PrivacyPolicy = lazy(()=> import('./Page/PrivacyPolicy') );
const ProductDetails = lazy(()=> import('./Page/ProductDetails') );
const Cart = lazy(()=> import('./Page/Cart') );
const CheckOut = lazy(()=> import('./Page/CheckOut') );
const Login = lazy(()=> import('./Page/Login') );
const Logout = lazy(()=> import('./Page/Logout') );
const UserDashboard = lazy(()=> import('./Page/UserDashboard') );
const ThankYou = lazy(()=> import('./Page/ThankYou') );
const SearchFormDemo = lazy(()=> import('./Page/SearchFormDemo') );
const SearchResult = lazy(()=> import('./Page/SearchResult') );
const InvoiceSlip = lazy(()=> import('./Page/InvoiceSlip') );
const AllCategory = lazy(()=> import('./Page/AllCategory') );
const MobileProductDetails = lazy(()=> import('./Page/MobileProductDetails') );
const ProductFinder = lazy(()=> import('./Page/ProductFinder') );
const EventDetails = lazy(()=> import('./Page/EventDetails') );
const OrderDetails = lazy(()=> import('./Page/OrderDetails') );
const TermOfUse = lazy(()=> import('./Page/TermOfUse') );
const Disclaimer = lazy(()=> import('./Page/Disclaimer') );
const ScrollToTop = lazy(()=> import('./Component/utitls/ScrollToTop') );
const ShippingDelivery = lazy(()=> import('./Page/ShippingDelivery') );
const CancellationRefund = lazy(()=> import('./Page/CancellationRefund') );
const Resource = lazy(()=> import('./Page/Resource') );
const DealershipForm = lazy(()=> import('./Page/DealershipForm') );
function App() {
  return (
    <>
      <Router scrollToTop>
        <ScrollToTop />
        <div className="wrapper">
          <LoginPopup />
          <Suspense>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/category/:categorySlug" element={<ProductCategoryHalf />} />
                <Route path="/all-category" element={<AllCategory />} />
                <Route path="/product-type/:ProductType" element={<Product />} />
                <Route path="/research" element={<Research />} />
                <Route path="/infrastructure" element={<Infrastructure />} />
                <Route path="/network" element={<Network />} />
                <Route path="/store" element={<Store />} />
                <Route path="/product/:CategorySlug/:productslug" element={<ProductDetails />} />
                <Route path="/product-specifications/:productslug" element={<MobileProductDetails />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/reach-us" element={<ReachUs />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/checkout" element={<CheckOut />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<UserDashboard />} />
                <Route path="/thank-you" element={<ThankYou />} />
                <Route path="/search-form-demo" element={<SearchFormDemo />} />
                <Route path="/search-result" element={<SearchResult />} />
                <Route path="/invoice/:invnumber" element={<InvoiceSlip />} />
                <Route path="/order-details/:invnumber" element={<OrderDetails />} />
                <Route path="/product-finder" element={<ProductFinder />} />
                <Route path="/event/:eventslug" element={<EventDetails />} />
                <Route path="/term-of-use" element={<TermOfUse />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/shipping-and-delivery" element={<ShippingDelivery />} />
                <Route path="/cancellation-and-refund" element={<CancellationRefund />} />
                <Route path="/resources" element={<Resource />} />
                <Route path="/dealership-form" element={<DealershipForm />} />
              </Routes>
            </Suspense>
        </div>
      </Router>
    </>
  );
}

export default App;
